<template >
    <b-overlay :show="showLoader" >
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.deleted_bills')">
            <CustomTable  :fields="fields" api-url="/api/client/v1/archived/open_tabs">
                <template #actions="{item}">
                    <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="viewDetails(item)">
                        {{ $t('general.details') }}
                    </BButton>
                </template>
            </CustomTable>
        </b-card>

        <OpenTabDetails ref="openTabDetails" :tables="tables"></OpenTabDetails>
    </b-overlay>
</template>

<script>
    import {BButton, BCard, BOverlay} from 'bootstrap-vue'
    import OpenTabDetails from '@/views/ArchivedOpenTabs/components/OpenTabDetails.vue'
    import * as Sentry from '@sentry/vue'
    import CustomTable from '@/components/CustomTable.vue'
    import {Formaters} from '@/libs/formaters'


    export default {
        components:{CustomTable, BButton, OpenTabDetails, BCard, BOverlay},
        data() {
            return {
                showLoader: false,
                business_units: [],
                cash_registers: [],
                employees: [],
                tables: []
            }
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'deleted_timestamp',
                        label: this.$t('table_fields.deleted'),
                        filter: true,
                        filter_type: 'date',
                        formatter: Formaters.formatDate,
                        permissions: true
                    },
                    {
                        key: 'employee.name',
                        label: this.$t('table_fields.employee'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.employees,
                        filterParamsName: 'name',
                        filterKey: 'employee.id', // po cem filtrira, default = key
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'products',
                        label: this.$t('table_fields.number_of_deleted_products'),
                        filter: false,
                        formatter: Formaters.formatNumberOfProducts,
                        permissions: true
                    },
                    {
                        key: 'business_unit.name',
                        label: this.$t('table_fields.business_unit'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.businessUnits,
                        filterParamsName: 'name',
                        filterKey: 'business_unit.id',
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'table_id',
                        label: this.$t('table_fields.table'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.tables,
                        filterParamsName: 'name',
                        filterKey: 'table_id',
                        formatterItem: this.getTableName,
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'cash_register.cash_register_id',
                        label: this.$t('table_fields.cash_register'),
                        filter: true,
                        filter_type: 'select',
                        filterParams: this.cashRegisters,
                        filterParamsName: 'cash_register_id',
                        filterKey: 'cash_register.id',
                        reduce: ele => ele.id,
                        permissions: true
                    },
                    {
                        key: 'total_price',
                        label: this.$t('table_fields.total_price'),
                        filter: false,
                        filterKey: 'total_price.w',
                        filterFormatter: Formaters.formatNumberForFilter,
                        formatter: Formaters.formatPrice,
                        permissions: true
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true
                    }
                ]
            }
        },
        methods:{
            getNumberOfProducts(item) {
                if (!item || !item.products) return
                return item.products.length
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const res1 = this.$http.get('/api/client/v1/business_units/')
                    const res2 = this.$http.get('/api/client/v1/cash_registers/?getAll=true')
                    const res3 = this.$http.get('/api/client/v1/employees/')
                    const res4 = this.$http.get('/api/client/v1/work_premises/')

                    const responses = await Promise.all([res1, res2, res3, res4])

                    this.business_units =  responses[0].data ?? []
                    this.cash_registers = responses[1].data.items ?? []
                    this.employees = responses[2].data ?? []
                    this.work_premises = responses[3].data ?? []

                    this.prepareTables()

                    const indexBusinessUnits = this.fields.findIndex(ele => ele.key === 'business_unit.name')
                    const indexCashRegisters = this.fields.findIndex(ele => ele.key === 'cash_register.cash_register_id')
                    const indexEmployees = this.fields.findIndex(ele => ele.key === 'employee.name')
                    const indexTables = this.fields.findIndex(ele => ele.key === 'table_id')

                    if (indexBusinessUnits >= 0) {
                        this.fields[indexBusinessUnits].filterParams = this.businessUnits
                    }
                    if (indexCashRegisters >= 0) {
                        this.fields[indexCashRegisters].filterParams = this.cashRegisters
                    }
                    if (indexEmployees >= 0) {
                        this.fields[indexEmployees].filterParams = this.employees
                    }
                    if (indexTables >= 0) {
                        this.fields[indexTables].filterParams = this.tables
                    }

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            viewDetails(data) {
                this.$refs.openTabDetails.open(data)
            },
            prepareTables() {
                for (const work_premise of this.work_premises) {
                    for (const table of work_premise.tables) {
                        const tableTmp = {
                            id: table.id,
                            name: `${work_premise.name} - ${table.name}`
                        }
                        this.tables.push(tableTmp)
                    }
                }
            },
            getTableName(data) {
                const table = this.tables.find((x) => x.id === data.table_id)
                if (!table) return '/'
                return table.name
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>